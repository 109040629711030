<template>
  <BaseLayout @close="closeContract">
    <Filters :allowedFilters="allowedFilters" @apply_filters="changeData" />

    <div class="contracts dashboard__card">
      <div class="devices__chart">
        <div class="doughnut__chart">
          <ContractsChart />
        </div>
      </div>
      <ContractsStatCards v-if="contracts.data" />
    </div>

    <TableComponent
      ref="tableRef"
      v-if="contracts.data"
      @open="openContract"
      name="Contracts"
      :data="contracts"
      :fields="fields"
      @updatePagination="updateTable"
      :computeStatus="computeStatus"
    />

    <div class="view__bg">
      <router-view @close="closeContract" />
    </div>
  </BaseLayout>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";

import moment from "moment";

import BaseLayout from "@/components/layout/BaseLayout.vue";
import TableComponent from "@/components/resusables/table/Table.vue";
import Filters from "@/components/resusables/filters/Filters.vue";
import ContractsChart from "@/components/utilizations/ContractsChart.vue";
import ContractsStatCards from "../../components/contracts/StatCards.vue";

import store from "@/store";
import router from "@/router";
import { FilterScopeEnums } from "@/store/modules/filters/types";

export default defineComponent({
  name: "ContractsPage",
  components: {
    Filters,
    TableComponent,
    ContractsStatCards,
    BaseLayout,
    ContractsChart,
  },

  methods: {
    changeData(filter) {
      this.$refs.tableRef?.resetSorting();

      store.dispatch("contracts/getContracts");
      store.dispatch("contracts/getOrderCosts");
      store.dispatch("devices/getVendors");
      store.dispatch("analytics/getDevicesReconciliationCount");
      store.dispatch("analytics/getUtilizationData", { filter });
      store.dispatch("analytics/getFinancialData", { filter });
    },
  },

  setup() {
    const computeStatus = (row) => {
      if (row.cycle === null) return "-";
      if (moment(row.end_date).isAfter(moment().format("YYYY-MM-DD"))) {
        return "Active";
      }
      return "Ended";
    };

    const fields = [
      {
        key: "document_id",
        label: "PO #",
        sortable: true,
        order_key: "document_id",
      },
      {
        key: ["invoice", "invoice_number"],
        type: "object",
        label: "Invoice #",
        sortable: false,
      },
      { key: "type", type: "contract_type", label: "Type", sortable: false },
      {
        key: ["vendor", "company_name"],
        type: "object",
        label: "Vendor",
        sortable: false,
      },
      {
        key: ["subcategory", "category", "name"],
        label: "Category",
        type: "object",
        sortable: false,
        order_key: "category",
      },
      {
        key: ["subcategory", "name"],
        label: "Subcategory",
        type: "object",
        sortable: false,
        order_key: "subcategory",
      },
      {
        key: "quantity",
        label: "# of Devices",
        sortable: true,
        order_key: "device_count",
      },
      {
        key: "leakage",
        label: "# of Leakages",
        type: "array_len",
        sortable: false,
        order_key: "leakage",
      },
      { key: "start_date", type: "date", label: "Start Date", sortable: false },
      { key: "end_date", type: "date", label: "End Date", sortable: false },
      { key: "amount", type: "contract_cost", label: "Cost", sortable: false },
      {
        key: "savings",
        type: "contract_maintenance",
        label: "Savings",
        sortable: false,
      },
      {
        key: "status",
        label: "Status",
        type: "status",
        sortable: false,
      },
    ];

    onMounted(() => {
      getContracts();
    });

    const openContract = (contract) => {
      store.commit("contracts/SET_CONTRACT", contract);
      router.push({ name: "ViewContract", params: { id: contract.id } });
    };

    const closeContract = () => {
      router.push({ name: "Contracts" });
    };

    const allowedFilters = [
      FilterScopeEnums.SUBCATEGORY,
      FilterScopeEnums.VENDOR,
    ];

    const contracts = computed(() => {
      return store.getters["contracts/contracts"];
    });

    const getContracts = () => {
      store.dispatch("contracts/getContracts");
      store.dispatch("devices/getHospitals");
      store.dispatch("devices/getVendors");
    };

    const updateTable = (res) => {
      store.dispatch("contracts/getContracts", res);
    };

    return {
      fields,
      updateTable,
      openContract,
      closeContract,
      contracts,
      computeStatus,
      allowedFilters,
    };
  },
});
</script>

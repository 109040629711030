<template>
  <div class="filters">
    <div class="filters__values">
      <div v-if="selecting.organization" class="filters__values__item">
        <span>{{ hospitalName }}</span>
        <button disabled @click="removeFilter('organization', null)">
          <BaseIcon icon="filter/close" />
        </button>
      </div>

      <FilterItem
        v-if="filters.department"
        filter="department"
        :scope="filters.department"
        @remove_filter="removeFilter"
      />
      <FilterItem
        v-if="filters.category"
        filter="category"
        :scope="filters.category"
        @remove_filter="removeFilter"
      />
      <FilterItem
        v-if="
          filters.subCategory &&
          allowedFilters.includes(FilterScopeEnums.SUBCATEGORY)
        "
        filter="subCategory"
        :scope="filters.subCategory"
        @remove_filter="removeFilter"
      />
      <FilterItem
        v-if="
          filters.vendor && allowedFilters.includes(FilterScopeEnums.VENDOR)
        "
        filter="vendor"
        keyName="brand_name"
        :scope="filters.vendor"
        @remove_filter="removeFilter"
      />
      <FilterItem
        v-show="
          filters.deviceStatuses &&
          allowedFilters.includes(FilterScopeEnums.DEVICE_STATUSES)
        "
        :isObject="false"
        filter="deviceStatuses"
        :scope="filters.deviceStatuses"
        @remove_filter="removeFilter"
        :disabled="disableStatus"
      />
      <FilterItem
        v-show="
          filters.reconciliationStatuses &&
          filters.reconciliationStatuses.length > 0 &&
          allowedFilters.includes(FilterScopeEnums.RECONCILIATION_STATUSES)
        "
        v-for="(status, index) in filters.reconciliationStatuses"
        :key="index"
        :index="index"
        :isObject="false"
        filter="reconciliationStatuses"
        :scope="status"
        @remove_filter="removeFilter"
      />

      <FilterItem
        v-if="
          filters.locationUnit &&
          allowedFilters.includes(FilterScopeEnums.LOCATION_UNIT)
        "
        filter="locationUnit"
        :scope="filters.locationUnit"
        @remove_filter="removeFilter"
      />
    </div>
    <div class="filters__inputs">
      <button
        @click="toggleFilter"
        class="modals-trigger filters__input__group"
      >
        <BaseIcon icon="filter/filter-alt" />
      </button>
      <DateRangePicker v-if="showDatePicker" @date_updated="dateUpdated" />
      <div
        class="modals filters__input__group__list"
        :class="{ active: showFilter }"
      >
        <div class="filters__input__group__list__head">
          <div class="filters__input__group__list__head__title">
            <h3>Filters</h3>
            <a @click="clearFilter">Clear all</a>
          </div>
          <!--          <div class="filters__input__group__list__footer">-->
          <!--            <button-->
          <!--              @click="applyFilters"-->
          <!--              class="filters__input__group__list__button"-->
          <!--            >-->
          <!--              Apply Filters-->
          <!--            </button>-->
          <!--          </div>-->
        </div>
        <div class="filters__input__group__list__body">
          <div class="filters__input__group__list__body__item">
            <button
              @click="selectFilter(0)"
              class="filters__input__group__list__body__item__title"
            >
              <span>Organizations</span>
              <BaseIcon icon="expand-more" />
            </button>
            <div
              class="filters__input__group__list__body__item__list"
              :class="{ active: activeFilter === 0 }"
            >
              <div>
                <div
                  v-for="(hospital, index2) in hospitals"
                  :key="index2"
                  class="filters__input__group__item"
                >
                  <BaseRadio :checked="true" />
                  <BaseLabel :title="hospital.name" />
                </div>
              </div>
            </div>
          </div>

          <FilterInputGroup
            name="Departments"
            :index="1"
            :activeFilter="activeFilter"
            filter="department"
            :scopeList="departments"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
          />

          <FilterInputGroup
            name="Categories"
            :index="2"
            :activeFilter="activeFilter"
            filter="category"
            :show="Boolean(selecting.department)"
            :scopeList="categories"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
          />

          <FilterInputGroup
            name="Subcategories"
            :index="3"
            :activeFilter="activeFilter"
            filter="subCategory"
            :show="Boolean(selecting.department && selecting.category)"
            :scopeList="subCategories"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
            v-show="allowedFilters.includes(FilterScopeEnums.SUBCATEGORY)"
          />

          <FilterInputGroup
            name="Vendors"
            :index="4"
            :activeFilter="activeFilter"
            filter="vendor"
            :scopeList="vendors"
            scopeObjName="brand_name"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
            v-show="allowedFilters.includes(FilterScopeEnums.VENDOR)"
          />
          <FilterInputGroup
            name="Status"
            :index="6"
            :activeFilter="activeFilter"
            filter="deviceStatuses"
            :scopeList="deviceStatuses"
            :objectArray="false"
            :isGrouped="false"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
            v-show="allowedFilters.includes(FilterScopeEnums.DEVICE_STATUSES)"
          />
          <FilterInputGroup
            name="Reconciliation Status"
            :index="5"
            :activeFilter="activeFilter"
            filter="reconciliationStatuses"
            :scopeList="reconciliationStatusesGroupedList"
            :objectArray="false"
            :multiple="true"
            :isGrouped="true"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
            v-show="
              allowedFilters.includes(FilterScopeEnums.RECONCILIATION_STATUSES)
            "
          />

          <FilterInputGroup
            name="Unit"
            :index="7"
            :activeFilter="activeFilter"
            filter="locationUnit"
            :scopeList="roomsUnits"
            :selecting="selecting"
            @check="check"
            @select_filter="selectFilter"
          />
        </div>

        <div class="filters__input__group__list__footer">
          <button
            @click="applyFilters"
            class="filters__input__group__list__button"
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import BaseRadio from "@/components/form/BaseRadio.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import store from "@/store";
import { biomedEventTypes } from "@/store/modules/filters/types";
import {
  DEVICE_STATUSES,
  DeviceStatusEnums,
} from "@/store/modules/devices/types";
import FilterItem from "@/components/resusables/filters/FilterItem.vue";
import FilterInputGroup from "@/components/resusables/filters/FilterInputGroup.vue";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import DateRangePicker from "@/components/form/DateRangePicker.vue";
import getEnv from "@/utils/env";
import { useRoute } from "vue-router";

interface SelectType {
  organization: any;
  department: any;
  category: any;
  subCategory: any;
  vendor: any;
  reconciliationStatuses: string[];
  deviceStatuses: any;
  locationUnit: any;
}

export default defineComponent({
  name: "FiltersComponent",
  props: {
    allowedFilters: {
      type: Array,
      required: false,
      default: () => [],
    },
    showDatePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    DateRangePicker,
    FilterInputGroup,
    FilterItem,
    BaseIcon,
    BaseLabel,
    BaseRadio,
  },
  mounted() {
    // console.log("Config:", this.$app.config);
  },

  setup(props, { emit }) {
    const activeFilter = ref(0);

    const route = useRoute();

    const hospitals = computed(() => {
      return store.getters["devices/hospitals"];
    });

    const hospitalName = computed(() => {
      return hospitals.value &&
        hospitals.value.length > 0 &&
        hospitals.value[0].name
        ? hospitals.value[0].name
        : "";
    });

    const defaultFilter = {
      organization: hospitalName,
      department: "",
      category: "",
      subCategory: "",
      vendor: "",
      reconciliationStatuses: [],
      deviceStatuses: DeviceStatusEnums.AVAILABLE,
      locationUnit: "",
    };

    const selecting = ref<SelectType>({ ...defaultFilter });

    const reconciliationStatusesList = [
      "confirmed",
      "registered",
      "missing",
      "leakage",
      "unpurchased",
      "undocumented",
      "unverified",
    ];

    const reconciliationStatusesGroupedList = {
      Verified: [
        "registered",
        "confirmed",
        "undocumented",
        "unbooked",
        "rogue",
      ],
      Unverified: ["missing", "phantom"],
    };

    onMounted(() => {
      selecting.value = { ...filters.value };

      applyFilters();
    });

    watch(
      () => ({ ...selecting.value }),
      (newVal, oldVal) => {
        const oldDepartment = oldVal.department.id;
        const newDepartment = newVal.department.id;

        const oldCategory = oldVal.category.id;
        const newCategory = newVal.category.id;

        if (oldDepartment && oldDepartment !== newDepartment) {
          selecting.value["category"] = "";
          selecting.value["subCategory"] = "";
        }
        if (oldCategory && oldCategory !== newCategory) {
          selecting.value["subCategory"] = "";
        }
      }
    );

    const departments = computed(() => {
      return store.getters["devices/departments"];
    });

    const categories = computed(() => {
      return store.getters["devices/categories"];
    });

    const subCategories = computed(() => {
      return store.getters["devices/subCategories"];
    });

    const vendors = computed(() => {
      return store.getters["devices/vendors"];
    });

    const roomsUnits = computed(() => {
      return store.getters["locations/roomsUnits"];
    });

    const filters = computed(() => {
      return store.getters["filters/filters"];
    });

    const showFilter = computed(() => {
      return store.getters["dropdowns/filters"];
    });

    const selectFilter = (index: number) => {
      if (activeFilter.value === index) {
        activeFilter.value = -1;
      } else {
        activeFilter.value = index;
      }
    };

    const dateUpdated = () => {
      emit("date_updated");
    };

    const check = (
      field: string,
      value: any,
      objectArray: boolean,
      multiple: boolean,
      checked: boolean
    ) => {
      selecting.value["organization"] = hospitalName;
      if (!multiple) {
        selecting.value[field] = value;
      } else {
        const res = selecting.value[field] ? selecting.value[field] : [];
        if (checked) {
          selecting.value[field] = [...new Set([...res, value])];
        } else {
          if (objectArray) {
            selecting.value[field] = res.filter(
              (scope) => scope.id != value.id
            );
          } else {
            selecting.value[field] = res.filter((scope) => scope != value);
          }
        }
      }
      if (field === "department") {
        store.dispatch("devices/getCategories", {
          departmentId: value.id,
        });
      } else if (field === "category") {
        store.dispatch("devices/getSubCategories", {
          departmentId: selecting.value.department.id,
          categoryId: value.id,
        });
      }
    };

    const removeFilter = (field: string, index: number | null) => {
      if (index === null) {
        selecting.value[field] = "";
      } else {
        selecting.value[field] = selecting.value[field].filter(
          (_, i) => i !== index
        );
      }
      applyFilters();
    };

    const clearFilter = () => {
      store.commit("filters/SET_FILTERS", { ...defaultFilter });

      selecting.value = { ...defaultFilter };

      applyFilters();
    };

    const applyFilters = () => {
      let filter = Object.assign(
        {},
        JSON.parse(JSON.stringify(selecting.value))
      );
      store.commit("filters/SET_FILTERS", { ...filter });
      emit("apply_filters", filter);
      store.commit("dropdowns/CLOSE_DROPDOWN", "filters");
    };

    const toggleFilter = () => {
      store.dispatch("dropdowns/toggleDropdown", "filters");
    };

    const isFindDevices = computed(() => {
      return route.name === "FindDevices";
    });

    const disableStatus = computed(() => {
      return isFindDevices.value;
    });

    const deviceStatuses = computed(() => {
      return DEVICE_STATUSES.filter(
        (status) =>
          !isFindDevices.value ||
          (isFindDevices.value && status !== "unavailable")
      );
    });

    return {
      showFilter,
      DEVICE_STATUSES,
      toggleFilter,
      activeFilter,
      selectFilter,
      check,
      filters,
      removeFilter,
      selecting,
      hospitalName,
      applyFilters,
      categories,
      hospitals,
      departments,
      clearFilter,
      subCategories,
      vendors,
      roomsUnits,
      dateUpdated,
      biomedEventTypes,
      getEnv,
      FilterScopeEnums,
      disableStatus,
      reconciliationStatusesList,
      reconciliationStatusesGroupedList,
      deviceStatuses,
    };
  },
});
</script>

<style scoped></style>

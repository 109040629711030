export default function getEnv(name) {
  const environment = getEnvVar("VUE_APP_SQUID_ENVIRONMENT");
  if (environment === "local") {
    return getEnvVar(name);
  }
  const host = window.location.host;
  const subdomain = host.split(".")[0].toUpperCase();
  const prodEnv = name.replace("SQUID", subdomain);
  return getEnvVar(prodEnv) ?? getEnvVar(name);
}

export function getSubDomain() {
  const environment = getEnvVar("VUE_APP_SQUID_ENVIRONMENT");
  if (environment === "local") {
    return environment;
  }
  const host = window.location.host;
  return host.split(".")[0].toLowerCase();
}

function getEnvVar(name) {
  // console.log({
  //   name,
  //   configs: window?.configs,
  // });
  // return window?.configs?.[name] || process.env[name];
  return process.env[name] || window?.configs?.[name];
}

import moment from "moment";
import { helpers } from "@/composables/helpers";

export function contracts() {
  const { formatMoney, dateIsPresent } = helpers();

  const orderTypeName = {
    purchase: "Purchase",
    maintenance: "Service",
    rental: "Rental",
    shipping: "Shipping",
    preventative_maintenance: "Maintenance",
    rent_to_own: "Rent-to-own",
  };

  const recurringOrderTypes = [
    "maintenance",
    "rental",
    "rent_to_own",
    "preventative_maintenance",
  ];

  const getContractType = (contract) => {
    return orderTypeName[contract.type] ?? contract.type;
  };

  const getContractCost = (
    contract,
    amount_type = "amount",
    formatted = true
  ) => {
    if (recurringOrderTypes.includes(contract.type)) {
      const period = getContractPeriods(contract);
      const total = period * parseFloat(contract[amount_type]);
      return formatted ? `$${formatMoney(total)}` : total;
    }

    return formatted
      ? `$${formatMoney(parseFloat(contract[amount_type]))}`
      : parseFloat(contract[amount_type]);
  };

  const getContractMaintenance = (contract) => {
    let amount = 0;

    if (contract.type === "maintenance") {
      const period = getContractPeriods(contract);
      amount +=
        period * parseFloat(contract.unit_amount) * contract.leakage.length;
    }

    return `$${formatMoney(amount)}`;
  };

  const getContractPeriods = (contract) => {
    if (!dateIsPresent(contract.end_date)) {
      return contract.period;
    }

    if (contract.cycle === "month") {
      return Math.ceil(
        moment(contract.end_date).diff(
          moment().format("YYYY-MM-DD"),
          "months",
          true
        )
      );
    } else if (contract.cycle === "year") {
      return Math.ceil(
        moment(contract.end_date).diff(
          moment().format("YYYY-MM-DD"),
          "years",
          true
        )
      );
    }
  };

  const getContractUnverifiedCount = (contract) => {
    return contract.unverified ? contract.unverified.length : 0;
  };

  return {
    orderTypeName,
    getContractMaintenance,
    getContractUnverifiedCount,
    getContractType,
    getContractCost,
  };
}

<template>
  <div class="access-points__tabs__item">
    <div class="access-points__tabs__item__left">
      <BaseIcon
        class="access-points__tabs__item__status"
        :icon="mapped ? 'access-point/mapped' : 'access-point/rogue'"
        :class="accessPoint.status"
      />
      <div class="access-points__tabs__item__content">
        <div class="access-points__item__labels">
          <h4 class="access-points__item__titles">
            <span class="access-points__item__label">{{
              accessPoint ? accessPoint.label : "-"
            }}</span>
            <template v-if="accessPoint.model">
              <span class="access-points__item__stop">•</span>
              <span class="access-points__item__model">{{
                removeFirstSubstringAndTrim(accessPoint.model)
              }}</span>
            </template>
          </h4>
          <div class="access-points__item__labels__icons">
            <span
              class="access-point__item__icon"
              v-if="showAccessPointIcon(accessPoint)"
            >
              <BaseIcon
                v-if="accessPoint.is_anchor"
                icon="access-point/tag/anchor"
              />
              <BaseIcon
                v-else-if="accessPoint.is_ble_compatible"
                icon="access-point/tag/ble"
              />
            </span>

            <!--            <span-->
            <!--              class="access-point__item__status__badge"-->
            <!--              v-if="mapped"-->
            <!--              :class="accessPoint.status"-->
            <!--              >{{ accessPoint.status }}</span-->
            <!--            >-->
          </div>
        </div>
        <h6 v-if="accessPoint.lan_mac">
          {{ accessPoint.lan_mac }}
        </h6>
      </div>
    </div>
    <div class="access-points__tabs__item__right">
      <AccessPointTransmitPower
        v-if="accessPoint.status === 'online'"
        :mapped="mapped"
        :index="index"
        :access-point="accessPoint"
      />
      <div v-if="mapped" class="access-points__tabs__item__dropdown">
        <button
          class="access-points__tabs__item__dropdown__hamburger"
          @click="toggleAction"
        >
          <BaseIcon icon="hamburger" />
        </button>
        <div
          v-if="accessPoint.status"
          :class="{ active: activeIndex === index }"
          class="dropdown-list"
        >
          <button @click="updateAccessPoint(accessPoint)">Update</button>
          <button
            v-if="accessPoint.status && accessPoint.status === 'unavailable'"
            @click="deleteAccessPoint"
          >
            Delete
          </button>
        </div>
      </div>
      <button @click="openAdd" class="access-points__tabs__item__close" v-else>
        <BaseIcon icon="modal/add" />
      </button>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import BaseIcon from "../svg/BaseIcon";
import store from "@/store";
import { access_points } from "@/composables/maps/access_points";
import AccessPointTransmitPower from "@/components/access-points/AccessPointTransmitPower";
import { helpers } from "@/composables/helpers";

export default defineComponent({
  name: "AccessPointsListItem",
  components: { AccessPointTransmitPower, BaseIcon },
  props: {
    index: {
      type: Number,
      required: true,
    },
    accessPoint: {
      type: Object,
      required: false,
      default: null,
    },
    activeIndex2: {
      type: Number,
      required: false,
      default: null,
    },
    mapped: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const { accessPointsCoordinatesTransformed, showAccessPointIcon } =
      access_points();

    const { removeFirstSubstringAndTrim } = helpers();

    const activeIndex = computed(() => {
      return store.getters["dropdowns/accessPointListItem"];
    });

    const toggleAction = () => {
      store.commit("dropdowns/TOGGLE_ACCESS_POINT_LIST_ITEM", props.index);
    };

    const openAdd = () => {
      store.commit("accessPoints/SET_ACCESS_POINT", {
        label: props.accessPoint.label,
      });
      store.commit("accessPoints/SET_SHOW_ADD", true);
    };

    const updateAccessPoint = (accessPoint) => {
      const accessPointCoordinate =
        accessPointsCoordinatesTransformed.value.find(
          (accessPnt) => accessPnt.label === accessPoint.label
        );
      if (!accessPointCoordinate || !accessPointCoordinate.jsonId) {
        console.log("Access point doesnt have jsonId");
        return;
      }

      const currentFloor = computed(() => {
        return store.getters["locations/floorNumber"];
      });

      store.commit("accessPoints/SET_ACCESS_POINT", {
        label: accessPointCoordinate.label,
        status: accessPointCoordinate.status,
        floorNumber: currentFloor.value,
        jsonId: accessPointCoordinate.jsonId,
        setLocation: true,
        top: "40%",
        left: "40%",
        coordinate: accessPointCoordinate.coordinate,
      });
      store.commit("accessPoints/SET_SHOW_ADD", true);
    };

    const deleteAccessPoint = () => {
      store
        .dispatch("accessPoints/deleteAccessPoint", props.accessPoint)
        .then(async () => {
          await Promise.all([
            store.dispatch("locations/getAccessPoints"),
            store.dispatch("locations/getAccessPointsCoordinates"),
          ]);
          store.commit("dropdowns/TOGGLE_ACCESS_POINT_LIST_ITEM", -1);
        })
        .catch(() => {
          // console.log()
        });
    };

    return {
      toggleAction,
      openAdd,
      activeIndex,
      updateAccessPoint,
      deleteAccessPoint,
      showAccessPointIcon,
      removeFirstSubstringAndTrim,
    };
  },
});
</script>

<template>
  <div class="cost__card contracts__details">
    <CostCardItem title="Cost of Ownership">
      <CostCardListItem
        title="Total Cost"
        :price="`$${formatMoney(orderCosts.total_cost)}`"
      />
      <CostCardListItem title="Total Devices" :price="totalDevicesCount" />
    </CostCardItem>

    <CostCardItem title="Verified Cost">
      <CostCardListItem
        title="Total Cost"
        :price="`$${formatMoney(orderCosts.verified_cost)}`"
      />
      <CostCardListItem
        title="Verified Devices"
        :price="verifiedDevicesCount"
      />
      <CostCardListItem title="Missing Devices" :price="leakageDevicesCount" />
    </CostCardItem>

    <CostCardItem title="Savings Opportunity">
      <CostCardListItem title="Service" :price="serviceSavings" />
      <CostCardListItem title="Maintenance" price="-" />
      <CostCardListItem title="Rentals" price="-" />
    </CostCardItem>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "@/store";
import { helpers } from "@/composables/helpers";
import CostCardListItem from "@/components/resusables/cost/CostCardListItem.vue";
import CostCardItem from "@/components/resusables/cost/CostCardItem.vue";
import moment from "moment";

export default defineComponent({
  name: "StatCards",

  components: { CostCardListItem, CostCardItem },

  setup() {
    const { formatMoney } = helpers();

    const financialData = computed(() => {
      return store.getters["analytics/financialData"];
    });

    const contracts = computed(() => {
      return store.getters["contracts/contracts"];
    });

    const orderCosts = computed(() => {
      return store.getters["contracts/orderCosts"];
    });

    const devicesReconciliationCounts = computed(() => {
      return store.getters["analytics/devicesReconciliationCounts"];
    });

    const getDeviceReconciliationCount = (type) => {
      const count = devicesReconciliationCounts.value[type];
      return count ? count : 0;
    };

    const leakageDevicesCount = computed(() => {
      return formatMoney(getDeviceReconciliationCount("missing"));
    });

    const registeredDevicesCount = computed(() => {
      return getDeviceReconciliationCount("registered");
    });

    const unPurchasedDevicesCount = computed(() => {
      return getDeviceReconciliationCount("unpurchased");
    });

    const totalDevicesCount = computed(() => {
      return formatMoney(
        getDeviceReconciliationCount("confirmed") +
          getDeviceReconciliationCount("registered") +
          getDeviceReconciliationCount("undocumented") +
          getDeviceReconciliationCount("unbooked") +
          getDeviceReconciliationCount("missing")
      );
    });

    const verifiedDevicesCount = computed(() => {
      return formatMoney(
        getDeviceReconciliationCount("confirmed") +
          getDeviceReconciliationCount("registered") +
          getDeviceReconciliationCount("undocumented") +
          getDeviceReconciliationCount("unbooked")
      );
    });

    const serviceSavings = computed(() => {
      let amount = 0;
      // let documentIds: number[] = [];
      for (let contract of contracts.value.data) {
        // documentIds.push(contract.document_id);
        if (contract.type === "maintenance") {
          let diff = 0;
          if (contract.cycle === "month") {
            diff = moment(contract.end_date).diff(
              moment().format("YYYY-MM-DD"),
              "months"
            );
          } else if (contract.cycle === "year") {
            diff = moment(contract.end_date).diff(
              moment().format("YYYY-MM-DD"),
              "years"
            );
          }
          amount +=
            diff * parseFloat(contract.unit_amount) * contract.leakage.length;
        }
      }

      return `$${formatMoney(amount)}`;
    });

    return {
      financialData,
      formatMoney,
      verifiedDevicesCount,
      serviceSavings,
      totalDevicesCount,
      leakageDevicesCount,
      unPurchasedDevicesCount,
      registeredDevicesCount,
      orderCosts,
    };
  },
});
</script>

<template>
  <div :class="{ active: show }" class="add-access-point">
    <div class="form-head">
      <h3>Assign to clinician</h3>
      <button @click="close">
        <BaseIcon icon="modal/close" />
      </button>
    </div>

    <form @submit.prevent="submit" class="form">
      <p>Device will be assigned to:</p>
      <div class="form-group">
        <BaseLabel title="Clinician name" />
        <input
          v-model="accessPointData.label"
          class="base__input"
          placeholder="Enter clinician name"
          type="text"
          required
        />
      </div>
      <div class="form-footer">
        <button type="submit" class="form-footer__btn-full">
          Assign to Clinician
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from "vue";
import BaseIcon from "@/components/svg/BaseIcon.vue";
import BaseLabel from "@/components/form/BaseLabel.vue";
import store from "@/store";

export default defineComponent({
  name: "AssignToClinicianModal",
  components: { BaseLabel, BaseIcon },
  setup() {
    const disabled = ref(false);

    onMounted(() => {
      store.commit("findDevices/SET_SHOW_ASSIGN_TO_CLINICIAN", false);
    });

    const currentFloor = computed(() => {
      return store.getters["locations/floorNumber"];
    });

    const currentDevice = computed(() => {
      return store.getters["findDevices/currentDevice"];
    });

    const show = computed(() => {
      return store.getters["findDevices/showAssignToClinician"];
    });

    const accessPointData = ref({
      label: "",
      floorNumber: currentFloor.value,
    });

    const submit = () => {
      store.dispatch("toast/showToast", {
        title: `SERIAL ${currentDevice.value.serial_number} has been assigned to clinician.`,
        description: `Device: ${currentDevice.value.description} (${currentDevice.value.vendor?.brand_name})`,
      });
      close();
    };

    const close = () => {
      accessPointData.value.label = "";
      store.dispatch("findDevices/setModal", {
        modal: "showAssignToClinician",
        show: false,
      });
    };

    return {
      disabled,
      close,
      show,
      submit,
      accessPointData,
    };
  },
});
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cost__card contracts__details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CostCardListItem = _resolveComponent("CostCardListItem")!
  const _component_CostCardItem = _resolveComponent("CostCardItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_CostCardItem, { title: "Cost of Ownership" }, {
      default: _withCtx(() => [
        _createVNode(_component_CostCardListItem, {
          title: "Total Cost",
          price: `$${_ctx.formatMoney(_ctx.orderCosts.total_cost)}`
        }, null, 8, ["price"]),
        _createVNode(_component_CostCardListItem, {
          title: "Total Devices",
          price: _ctx.totalDevicesCount
        }, null, 8, ["price"])
      ]),
      _: 1
    }),
    _createVNode(_component_CostCardItem, { title: "Verified Cost" }, {
      default: _withCtx(() => [
        _createVNode(_component_CostCardListItem, {
          title: "Total Cost",
          price: `$${_ctx.formatMoney(_ctx.orderCosts.verified_cost)}`
        }, null, 8, ["price"]),
        _createVNode(_component_CostCardListItem, {
          title: "Verified Devices",
          price: _ctx.verifiedDevicesCount
        }, null, 8, ["price"]),
        _createVNode(_component_CostCardListItem, {
          title: "Missing Devices",
          price: _ctx.leakageDevicesCount
        }, null, 8, ["price"])
      ]),
      _: 1
    }),
    _createVNode(_component_CostCardItem, { title: "Savings Opportunity" }, {
      default: _withCtx(() => [
        _createVNode(_component_CostCardListItem, {
          title: "Service",
          price: _ctx.serviceSavings
        }, null, 8, ["price"]),
        _createVNode(_component_CostCardListItem, {
          title: "Maintenance",
          price: "-"
        }),
        _createVNode(_component_CostCardListItem, {
          title: "Rentals",
          price: "-"
        })
      ]),
      _: 1
    })
  ]))
}
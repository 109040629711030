import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-head" }
const _hoisted_2 = { class: "form-group" }
const _hoisted_3 = { class: "form-group" }
const _hoisted_4 = { class: "form-group" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_BaseLabel = _resolveComponent("BaseLabel")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{ active: _ctx.show }, "add-access-point"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Assign to patient", -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        _createVNode(_component_BaseIcon, { icon: "modal/close" })
      ])
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
      class: "form"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseLabel, { title: "Patient name" }),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.accessPointData.label) = $event)),
          placeholder: "Enter patient name",
          class: "base__input",
          type: "text",
          required: ""
        }, null, 512), [
          [_vModelText, _ctx.accessPointData.label]
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BaseLabel, { title: "Date Of Birth" }),
        _cache[4] || (_cache[4] = _createElementVNode("input", {
          class: "base__input",
          placeholder: "Enter patient date of birth",
          type: "date",
          required: ""
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BaseLabel, { title: "Patient ID" }),
        _cache[5] || (_cache[5] = _createElementVNode("input", {
          class: "base__input",
          placeholder: "Enter patient ID",
          type: "text",
          required: ""
        }, null, -1))
      ]),
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "form-footer" }, [
        _createElementVNode("button", {
          type: "submit",
          class: "form-footer__btn-full"
        }, " Assign to patient ")
      ], -1))
    ], 32)
  ], 2))
}
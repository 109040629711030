<template>
  <div class="cost__card devices__details">
    <CostCardItem title="Cost of Ownership">
      <CostCardListItem
        title="Total Cost"
        :price="`$${formatMoney(financialData.total)}`"
      />
      <CostCardListItem
        title="Average Cost"
        :price="`$${formatMoney(financialData.average)}`"
      />
    </CostCardItem>

    <CostCardItem title="Savings Opportunity">
      <CostCardListItem title="Service Contracts" />
      <CostCardListItem title="Rentals" />
    </CostCardItem>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import store from "@/store";
import { helpers } from "@/composables/helpers";
import CostCardItem from "@/components/resusables/cost/CostCardItem.vue";
import CostCardListItem from "@/components/resusables/cost/CostCardListItem.vue";

export default defineComponent({
  name: "DeviceStatCards",

  components: { CostCardListItem, CostCardItem },

  setup() {
    const { formatMoney } = helpers();

    const financialData = computed(() => {
      return store.getters["analytics/financialData"];
    });

    return { financialData, formatMoney };
  },
});
</script>

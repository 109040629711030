import { Module } from "vuex";
import { RootState } from "@/store/types";
import { getters } from "@/store/modules/contracts/getters";
import { mutations } from "@/store/modules/contracts/mutations";
import { actions } from "@/store/modules/contracts/actions";
import { ContractsState } from "@/store/modules/contracts/types";

export const ContractsData: ContractsState = {
  contracts: {},
  contract: {},
  orderCosts: {},
};

export const contracts: Module<ContractsState, RootState> = {
  namespaced: true,
  state: ContractsData,
  getters,
  actions,
  mutations,
};

export default contracts;

import { MutationTree } from "vuex";
import { ToastState } from "@/store/modules/toast/types";

export const mutations: MutationTree<ToastState> = {
  SHOW_TOAST(state, { title, description }) {
    state.title = title;
    state.description = description;
    state.visible = true;
  },
  HIDE_TOAST(state) {
    state.visible = false;
  },
};

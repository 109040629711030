import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import {
  DashboardChartDataType,
  DashboardState,
} from "@/store/modules/dashboard/types";
import { helpers } from "@/composables/helpers";
import { FilterScopeEnums } from "@/store/modules/filters/types";
import { formatChartsData } from "@/composables/charts/format-data";
import store from "@/store";

export const actions: ActionTree<DashboardState, RootState> = {
  async getDashboardData({ commit, dispatch }, payload = null) {
    const filter = payload ? payload : this.getters["filters/filters"];

    const { formatMoney } = helpers();
    const { formatLineChartData, formatDoughnutData } = formatChartsData();

    let scope,
      scopesArray: any[] = [];

    store.dispatch("loading/startLoading", true);

    if (filter.category && filter.category.id) {
      scope = FilterScopeEnums.CATEGORY;
      await store.dispatch("devices/getSubCategories", {
        departmentId: filter.department.id,
        categoryId: filter.category.id,
        hideProgressBar: true,
      });
      scopesArray = this.getters["devices/subCategories"];
    } else if (filter.department && filter.department.id) {
      scope = FilterScopeEnums.DEPARTMENT;
      await store.dispatch("devices/getCategories", {
        departmentId: filter.department.id,
        hideProgressBar: true,
      });
      scopesArray = this.getters["devices/categories"];
    } else {
      scope = FilterScopeEnums.ORGANIZATION;
      await store.dispatch("devices/getDepartments", {
        hideProgressBar: true,
      });
      scopesArray = this.getters["devices/departments"];
    }

    const data: DashboardChartDataType[] = [];

    let type;
    if (scope === FilterScopeEnums.ORGANIZATION) {
      type = "department";
    } else if (scope === FilterScopeEnums.DEPARTMENT) {
      type = "category";
    } else if (scope === FilterScopeEnums.CATEGORY) {
      type = "subCategory";
    }

    // console.log({ scopesArray });

    const allFilter = this.getters["filters/filters"];

    for (const [_, val] of scopesArray.entries()) {
      const filter = {
        [type]: val,
        vendor: allFilter.vendor,
        hideProgressBar: true,
      };

      // console.log({ scope, type });
      // eslint-disable-next-line prefer-const
      let [resLineChart, resDoughnutsChart, financialData] = await Promise.all([
        dispatch(
          "analytics/getUtilizationTimelineData",
          { filter },
          { root: true }
        ),

        dispatch(
          "devices/getStatusesCount",
          { filter, reset: false },
          { root: true }
        ),

        dispatch("analytics/getFinancialData", { filter }, { root: true }),
      ]);

      resDoughnutsChart = await formatDoughnutData(resDoughnutsChart);
      resLineChart = await formatLineChartData(resLineChart);

      data.push({
        scope,
        name: val.name,
        scopeValue: val,
        lineChart: resLineChart,
        doughnutChart: resDoughnutsChart,
        totalCost: `$${formatMoney(financialData.total)}`,
        groupBy: "day",
      });
    }

    commit("SET_DATA", data);

    store.dispatch("loading/doneLoading", true);
  },
};

import { ActionTree } from "vuex";
import { RootState } from "@/store/types";
import { AnalyticsState } from "@/store/modules/analytics/types";
import AxiosClient from "@/services/api";
import moment from "moment";
import { RepairTicketsStatusesEnums } from "@/store/modules/tickets/types";
const BASE_URL = "/v1";

export const actions: ActionTree<AnalyticsState, RootState> = {
  getUtilizationTimelineData(context, { filter = null }) {
    const last30Days = moment().subtract(30, "days").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    let url = `/analytics/utilization/timeline?date_from=${last30Days}&date_to=${dateTo}&`;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }

    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`, {
      params: { hideProgressBar: filter.hideProgressBar },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getFinancialData({ commit }, { filter = null, reset = true }) {
    let url = "/analytics/financial/cost?";

    filter = filter ? filter : this.getters["filters/filters"];

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`, {
      params: { hideProgressBar: filter.hideProgressBar },
    })
      .then((res) => {
        if (reset) {
          commit("SET_FINANCIAL_DATA", res.data);
        }
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getUtilizationData({ commit }, { filter = null, reset = true } = {}) {
    let url = "/analytics/utilization/status?";

    filter = filter ? filter : this.getters["filters/filters"];

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }

    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`, {
      params: { hideProgressBar: filter.hideProgressBar },
    })
      .then((res) => {
        if (reset) {
          commit("SET_UTILIZATION_DATA", res.data);
        }
        return res.data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getBiomedTimelineData({ commit }, { filter = null }) {
    const last30Days = moment().subtract(30, "days").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    let url = `/analytics/biomed/timeline?date_from=${last30Days}&date_to=${dateTo}&`;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }

    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`, {
      params: { hideProgressBar: filter.hideProgressBar },
    })
      .then((res) => {
        commit("SET_BIOMED_TIMELINE_DATA", res.data);
        return res.data;
      })
      .catch(() => {
        // if (err instanceof Error) {
        //   const message = err.message;
        //   return Promise.reject(new Error(message));
        // }
        commit("SET_BIOMED_TIMELINE_DATA", {
          date_from: "2023-01-16",
          date_to: "2023-02-15",
          group_by: "day",
          labels: [],
          closed: [],
          collected: [],
          diagnosed: [],
          escalated: [],
          on_hold: [],
          open: [],
          pending_assignment: [],
          pending_review: [],
          ready_for_work: [],
          repaired: [],
          replaced: [],
          work_in_progress: [],
        });
      });
  },

  async getBiomedMeantimesData({ commit, dispatch }, { filter = null }) {
    const [responseMeantimes, diagnosisMeantimes, repairMeantimes] =
      await Promise.all([
        dispatch("getBiomedMeantimes", {
          filter,
          status_from: RepairTicketsStatusesEnums.OPEN,
          status_to: RepairTicketsStatusesEnums.PENDING_ASSIGNMENT,
        }),

        dispatch("getBiomedMeantimes", {
          filter,
          status_from: RepairTicketsStatusesEnums.OPEN,
          status_to: RepairTicketsStatusesEnums.READY_FOR_WORK,
        }),

        dispatch("getBiomedMeantimes", {
          filter,
          status_from: RepairTicketsStatusesEnums.OPEN,
          status_to: RepairTicketsStatusesEnums.CLOSED,
        }),
      ]);

    commit("SET_BIOMED_MEANTIMES_DATA", {
      responseMeantimes,
      diagnosisMeantimes,
      repairMeantimes,
    });
  },

  getBiomedMeantimes(context, { filter, status_from, status_to }) {
    const last30Days = moment().subtract(30, "days").format("YYYY-MM-DD");
    const dateTo = moment().format("YYYY-MM-DD");

    let url = `/analytics/biomed/meantimes?date_from=${last30Days}&date_to=${dateTo}&status_from=${status_from}&status_to=${status_to}&`;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }

    if (filter.vendor && filter.vendor.id) {
      url += `vendor_id=${filter.vendor.id}&`;
    }
    if (filter.biomedMeantimesType) {
      // url += `type=${filter.biomedMeantimesType}&`;
      url += `type=corrective_maintenance`;
    }

    return AxiosClient.get(`${BASE_URL}${url}`, {
      params: { hideProgressBar: filter.hideProgressBar },
    })
      .then((res) => {
        return res.data;
      })
      .catch(() => {
        return {
          date_from: "2023-01-16",
          date_to: "2023-02-15",
          group_by: "day",
          labels: [],
          meantimes: [],
          status_from: "open",
          status_to: "ready_for_work",
        };
      });
  },

  getDevicesReconciliationCount({ commit }) {
    const filter = this.getters["filters/filters"];
    let url = `/analytics/reconciliation/status?`;

    if (filter.subCategory && filter.subCategory.id) {
      url += `subcategory_id=${filter.subCategory.id}&`;
    } else if (filter.category && filter.category.id) {
      url += `category_id=${filter.category.id}&`;
    } else if (filter.department && filter.department.id) {
      url += `department_id=${filter.department.id}&`;
    }
    return AxiosClient.get(`${BASE_URL}${url}`)
      .then((res) => {
        const data = res.data;
        commit("SET_DEVICES_RECONCILIATION_COUNTS", data);
        return data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },

  getAnalyticsUtilizationRates({ commit }, option = {}) {
    const url = `/analytics/utilization/rates?`;

    const date_from = this.getters["filters/dateFrom"];
    const date_to = this.getters["filters/dateTo"];
    const utilGroupBy = this.getters["devices/utilGroupBy"];

    return AxiosClient.get(`${BASE_URL}${url}`, {
      params: {
        date_from,
        date_to,
        group_by: utilGroupBy,
        ...option,
      },
    })
      .then((res) => {
        const data = res.data;
        commit("SET_UTILIZATION_RATES", data);
        return data;
      })
      .catch((err: Error) => {
        const message = err.message;
        return Promise.reject(new Error(message));
      });
  },
};

import { MutationTree } from "vuex";
import { ContractsState } from "@/store/modules/contracts/types";

export const mutations: MutationTree<ContractsState> = {
  SET_CONTRACTS(state: ContractsState, data: object) {
    state.contracts = data;
    return state.contracts;
  },
  SET_CONTRACT(state: ContractsState, data: object) {
    state.contract = data;
    return state.contract;
  },
  SET_ORDER_COSTS(state: ContractsState, data: object) {
    state.orderCosts = data;
    return state.orderCosts;
  },
};
